import {Avatar, AvatarCollection} from '../../../components/avatar';
import {PaywallClickBlocker} from '../../../components/paywall-click-blocker';
import {PermissionChecker} from '../../../components/permission';
import {RemoveFromPipelineButton} from '../../../components/pipelines-actions/remove-from-pipeline';
import {StatusSwitch} from '../../../components/status-switch';
import {TruncateLink} from '../../../components/truncate-link';
import {BffProfileInPipelineSuggestion, Permission} from '../../../types';
import {getUserFullName} from '../../../utils';
import Styles from './PipelineTag.module.scss';

type Props = {
  pipeline: BffProfileInPipelineSuggestion;
  onSuccess?: () => void;
  profileId: string;
};

export const PipelineTag = ({pipeline, profileId, onSuccess}: Props) => {
  const link = (
    <TruncateLink href={`/pipelines/${pipeline.id}`} contentClassName={Styles.pipelineLink}>
      {pipeline.name}
    </TruncateLink>
  );

  return (
    <div className={Styles.pipelineTag}>
      <div className={Styles.pipelineLinkWrapper}>
        <PermissionChecker
          permission={Permission.Pipeline}
          missingPlanFallback={<PaywallClickBlocker>{link}</PaywallClickBlocker>}
        >
          {link}
        </PermissionChecker>
      </div>
      <AvatarCollection size="sm" bgColor="gray">
        {pipeline.suggestedBy.map((creator, index) => (
          <Avatar size="sm" key={index} fullname={getUserFullName(creator)} />
        ))}
      </AvatarCollection>
      <StatusSwitch
        currentStatus={pipeline.status}
        profileId={profileId}
        pipelineId={pipeline.id}
        onChangeSuccess={onSuccess}
        className={Styles.statusSwitch}
      />
      <PermissionChecker permission={Permission.Pipeline}>
        <div className={Styles.removeFromPipelineButtonWrapper}>
          <RemoveFromPipelineButton
            pipelineId={pipeline.id}
            pipelineName={pipeline.name}
            profileId={profileId}
            onSuccess={onSuccess}
          />
        </div>
      </PermissionChecker>
    </div>
  );
};

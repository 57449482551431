import {Button} from '../../../components/button';
import {PlanDetails} from '../../../components/plan-details';
import {PaymentPeriod, PlanType} from '../../../types';
import {SubscriptionButton} from '../buttons/SubscriptionButton';
import {basePlanPricing, premiumPlanPricing} from './consts';
import Styles from './PlansOverview.module.scss';

export type PlansOverviewProps = {
  billingPeriod: PaymentPeriod;
  currentPlan?: PlanType;
  isTrial?: boolean;
};

export const PlansOverview = ({billingPeriod, currentPlan, isTrial}: PlansOverviewProps) => {
  const basePrice = basePlanPricing[billingPeriod];
  const premiumPrice = premiumPlanPricing[billingPeriod];

  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <PlanDetails
          name={PlanType.Free}
          billingPeriod={billingPeriod}
          price={0}
          isCurrentPlan={currentPlan === PlanType.Free}
          button={<SubscriptionButton requestedPlan={PlanType.Free} />}
          featuresTitle="Included in Free:"
        />
        <PlanDetails
          name={PlanType.Base}
          billingPeriod={billingPeriod}
          price={basePrice}
          isCurrentPlan={currentPlan === PlanType.Base}
          isTrial={isTrial}
          button={<SubscriptionButton requestedPlan={PlanType.Base} period={billingPeriod} />}
          featuresTitle="Everything in Free, plus:"
        />
        <PlanDetails
          name={PlanType.Premium}
          billingPeriod={billingPeriod}
          price={premiumPrice}
          isCurrentPlan={currentPlan === PlanType.Premium}
          isTrial={isTrial}
          button={<SubscriptionButton requestedPlan={PlanType.Premium} period={billingPeriod} />}
          featuresTitle="Everything in Base, plus:"
        />
      </div>
      <div className={Styles.planEnterprise}>
        <div>
          <h3 className={Styles.planName} data-intercom-target="pricing plan: enterprise">
            Enterprise
          </h3>
          <p>
            For data teams or product builders to leverage our API at scale.{' '}
            <a href="https://www.theswarm.com/pricing" target="_blank" className="underline" rel="noreferrer">
              Learn more
            </a>
          </p>
        </div>
        <div>
          <Button
            href="https://www.theswarm.com/company/contact"
            target="_blank"
            variant="tertiary"
            outline
            disableTitleCase
            tracking={{
              label: 'schedule a call',
              location: 'plan selection',
            }}
          >
            Schedule a call
          </Button>
        </div>
      </div>
    </div>
  );
};

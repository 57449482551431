import {CopyField} from '../../../components/copy-field';
import {Label} from '../../../components/form';
import {TabPageContent} from '../../../components/tabs';
import {useCurrentTeam} from '../../../hooks';

export const TeamID = () => {
  const {id: teamId} = useCurrentTeam();

  return (
    <TabPageContent heading="Team ID" subheading="Identifies your team and gets you an access to the API">
      <div>
        <Label>Your team ID</Label>
        <CopyField value={teamId} placeholder="Loading..." tracking={{label: 'copy team ID'}} />
      </div>
    </TabPageContent>
  );
};

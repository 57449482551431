import {useCallback, useEffect, useState} from 'react';
import {PiKeyFill as KeyIcon} from 'react-icons/pi';
import {useCheckApiKeyExists, useMutationCreateApiKey, useMutationGetApiKey} from '../../../api';
import {Button} from '../../../components/button';
import {CopyField} from '../../../components/copy-field';
import {Label} from '../../../components/form';
import {notify} from '../../../components/notifications';
import {PremiumFeatureClickWrapper} from '../../../components/premium-feature-wrapper';
import {TabPageContent} from '../../../components/tabs';
import {Permission} from '../../../types';

enum ApiKeyStatus {
  NotGenerated = 'NotGenerated',
  Hidden = 'Hidden',
  Revealed = 'Revealed',
}

export const ApiKey = () => {
  const [apiKeyStatus, setApiKeyStatus] = useState<ApiKeyStatus>(ApiKeyStatus.NotGenerated);
  const [apiKey, setApiKey] = useState<string | undefined>(undefined);
  const {data: keyGenerated, isLoading: checkingForKey} = useCheckApiKeyExists();

  useEffect(() => {
    if (keyGenerated) {
      setApiKeyStatus(ApiKeyStatus.Hidden);
    }
  }, [keyGenerated]);

  const noApiKeyGenerated = apiKeyStatus === ApiKeyStatus.NotGenerated;

  const keyPlaceholder = checkingForKey
    ? 'Checking for key...'
    : noApiKeyGenerated
      ? 'API key not generated yet'
      : '*****************';

  const mutationCreateApiKey = useMutationCreateApiKey({
    onSuccess: resp => {
      setApiKeyStatus(ApiKeyStatus.Revealed);
      setApiKey(resp.apiKey);
    },
    onError: () => {
      notify('Failed to generate API key');
    },
  });

  const mutationGetApiKey = useMutationGetApiKey({
    onSuccess: resp => {
      setApiKey(resp.apiKey);
      setApiKeyStatus(ApiKeyStatus.Revealed);
    },
    onError: () => {
      notify('Failed to reveal API key');
    },
  });

  const generateApiKey = useCallback(() => {
    mutationCreateApiKey.mutate();
  }, [mutationCreateApiKey]);

  const generateKeyButton = (
    <PremiumFeatureClickWrapper permission={Permission.APIKeyWrite}>
      <Button
        variant="primary"
        onClick={generateApiKey}
        disabled={checkingForKey}
        tracking={{label: 'generate API key'}}
      >
        Generate API Key
      </Button>
    </PremiumFeatureClickWrapper>
  );

  const revealKey = useCallback(() => {
    mutationGetApiKey.mutate();
  }, [mutationGetApiKey]);

  const revealKeyButton = (
    <PremiumFeatureClickWrapper permission={Permission.APIKeyRead}>
      <Button
        variant="primary"
        onClick={revealKey}
        disabled={apiKeyStatus === ApiKeyStatus.Revealed}
        tracking={{label: 'reveal API key'}}
      >
        Reveal API Key
      </Button>
    </PremiumFeatureClickWrapper>
  );

  return (
    <TabPageContent
      heading="API Key"
      subheading="Use your access key below to authenticate requests to The Swarm API"
    >
      <div>
        <Label>API Key</Label>
        <div className="flex items-center gap-3">
          <div className="flex-1">
            <CopyField
              value={apiKey}
              placeholder={keyPlaceholder}
              hidden={apiKeyStatus !== ApiKeyStatus.Revealed}
              icon={KeyIcon}
              iconClassName="text-swarm-gray-500"
              tracking={{label: 'copy API key'}}
            />
          </div>
          {noApiKeyGenerated ? generateKeyButton : revealKeyButton}
        </div>
      </div>
      <p className="mt-4 text-swarm-gray-700">
        Use this key when interacting with the The Swarm API from your production environment.
      </p>
    </TabPageContent>
  );
};

import {ProfilesBffColumns} from '../../../defs';

export const PIPELINES_SAVED_SEARCH_PREFERENCES_KEY = 'list_pipelines_saved_search_table_columns_6'; // postfix in the name to abandon old, not-compatible settings before columns naming changes

export const availableColumns = [
  ProfilesBffColumns.FullName,
  ProfilesBffColumns.AddToPipeline,
  ProfilesBffColumns.JobTitle,
  ProfilesBffColumns.JobCompanyName,
  ProfilesBffColumns.JobCompanyWebsite,
  ProfilesBffColumns.Tags,
  ProfilesBffColumns.TeamConnectionsStrength,
  ProfilesBffColumns.KeyRelationship,
  ProfilesBffColumns.ConnectedMembersCount,
  ProfilesBffColumns.Emails,
  ProfilesBffColumns.LocationName,
];

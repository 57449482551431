import {Role} from './api';

export type Team = {
  id: string;
  name: string;
  creatorId: string;
  disabled: boolean;
  membersCount: number;
  hasSubscription: boolean;
  mainUseCase?: TeamMainUseCase;
  companyLinkedinURL?: string;
  companyName?: string;
  mapped?: boolean;
  logoURL?: string;
  createdAt: string;
  updatedAt: string;
};

export type Membership = {
  id: string;
  userId: string;
  teamId: string;
  team: Team;
  role: Role;
  createdAt: string;
  updatedAt: string;
};

export type EnhancedTeam = Team & {
  role: Role;
  isActive: boolean;
};

export enum TeamMainUseCase {
  sales = 'Sales',
  recruiting = 'Recruiting',
  fundraising = 'Fundraising',
  vcNetwork = 'VC Network',
  personalNetwork = 'Personal network',
  communityNetwork = 'Online community',
  other = 'Other',
}

export enum HowDidYouHearAnswer {
  search = 'Search engine',
  friend = 'Recommended by a friend or colleague',
  linkedin = 'LinkedIn',
  twitter = 'Twitter/X',
  reddit = 'Reddit',
  podcast = 'Podcast',
  blogPost = 'Blog or article',
  other = 'Other',
}

export enum InterestedInOurImplementationServicesAnswer {
  yes = 'Yes, contact me to set up a call.',
  no = 'No, not at the moment.',
}

import cs from 'classnames';
import {uniqBy} from 'lodash';
import {useCallback, useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {FiUsers as UsersIcon} from 'react-icons/fi';
import {CompanyLink} from '../../components/company-link';
import {PermissionChecker} from '../../components/permission';
import {RequestIntro, RequestIntroButton, useRequestIntroModal} from '../../components/request-intro';
import {Tabs} from '../../components/tabs';
import {ProfileTagsList} from '../../components/tags';
import {Vcard} from '../../components/vcard';
import {VcardIntegrationsList} from '../../components/vcard-integrations-list';
import {useCurrentTeam, useQueryParam, useSortBffConnections} from '../../hooks';
import {BffProfile, Permission, SocialMediaEnum} from '../../types';
import {ConnectedMembers} from './connected-members';
import {ProfileInPipelines} from './pipelines';
import Styles from './SingleProfilePageContent.module.scss';
import {useTabsConfig} from './useTabsConfig';

type Props = {
  profile: BffProfile;
  refetchData: () => void;
};

const sanitizeProfiles = (profiles: BffProfile['info']['profiles']) => {
  const uniqueSocialProfiles = uniqBy(profiles, 'network');
  return uniqueSocialProfiles.map(({network = '', url = ''}) => ({
    network: network as SocialMediaEnum,
    url,
  }));
};

export const SingleProfilePageContent = ({profile, refetchData}: Props) => {
  const {id: teamId} = useCurrentTeam();

  const {
    id: profileId,
    info: {fullName, profiles},
    currentJob,
    connections: orgConnections,
  } = profile;
  const connections = useSortBffConnections({connections: orgConnections, currentUserOnTop: true});
  const connectedMembers = connections.map(connection => connection.user);

  const {introModalVisible, closeIntroModal, openIntroModal, introProfileId} = useRequestIntroModal();
  const showIntroModal = useCallback(() => openIntroModal(profileId), [openIntroModal, profileId]);

  const vcardProfiles = useMemo(() => sanitizeProfiles(profiles), [profiles]);
  const tabsConfig = useTabsConfig({profile, teamId, onScoreUpdate: refetchData});

  const requestIntroModalVisible = useQueryParam<string>('request-intro');
  useEffect(() => {
    if (requestIntroModalVisible) {
      showIntroModal();
    }
  }, [requestIntroModalVisible, showIntroModal]);

  return (
    <>
      <Helmet>
        <title>{`${fullName} - The Swarm`}</title>
      </Helmet>
      <div className={cs(Styles.profileWidthContainer, Styles.profileHeader)}>
        <div className={Styles.profileColumn}>
          <Vcard
            fullName={fullName}
            {...(currentJob
              ? {
                  specialization: currentJob.title || '',
                  where: (
                    <CompanyLink
                      companyId={currentJob.companyId}
                      companyName={currentJob.companyName || ''}
                      dataIntercomTarget="company-profile"
                      logoUrl={currentJob.companyLogoUrl}
                    />
                  ),
                }
              : {specialization: 'Unknown', where: 'Unknown'})}
            profiles={vcardProfiles}
            icons={
              profile.crms && (
                <PermissionChecker permission={Permission.CRM}>
                  <VcardIntegrationsList crms={profile.crms.map(crm => crm.crm)} />
                </PermissionChecker>
              )
            }
          />
          <ProfileTagsList
            targetId={profileId}
            targetType="profile"
            tags={profile.tags}
            onAssignSuccess={refetchData}
            onUnassignSuccess={refetchData}
            className="mt-4"
          />
        </div>
        <div className={Styles.profileColumn}>
          <ProfileInPipelines profile={profile} refetchProfile={refetchData} />
        </div>
        <div className={Styles.profileColumn}>
          {!!connectedMembers.length && (
            <>
              <div>
                <h4
                  className="flex items-center gap-2 pb-2 font-semibold"
                  data-intercom-target="connected-to"
                >
                  <UsersIcon /> Connected to
                </h4>
                <ConnectedMembers connections={connections} />
              </div>
              <RequestIntroButton
                showModal={showIntroModal}
                connectors={connectedMembers}
                profileId={profileId}
              />
            </>
          )}
        </div>
      </div>
      <Tabs tabs={tabsConfig} defaultTab="Profile Details" inPage trackEvents />
      <RequestIntro
        profileId={introProfileId}
        onClose={closeIntroModal}
        visible={introModalVisible}
        onSubmitRequestWithPipelineSuccess={refetchData}
      />
    </>
  );
};

import {useState} from 'react';
import {Helmet} from 'react-helmet';
import {FiChevronLeft as BackIcon} from 'react-icons/fi';
import {useParams} from 'react-router-dom';
import {useQueryBffCompany, useQueryCompanyCrmData} from '../../api';
import {LoadingSpinner} from '../../components/animations';
import {Button} from '../../components/button';
import {CrmExportMenu} from '../../components/crm-export-menu';
import {NoResults} from '../../components/no-results';
import {PageHeader} from '../../components/page-header';
import {PaginationButtons} from '../../components/pagination-buttons';
import {PremiumFeatureClickWrapper} from '../../components/premium-feature-wrapper';
import {ReportDotsMenu} from '../../components/report-dots-menu';
import {useTablePagination} from '../../components/table';
import {Tabs} from '../../components/tabs';
import {useCheckPermission, useConditionallyGoBack} from '../../hooks';
import {AppLayout} from '../../layouts';
import {Permission} from '../../types';
import {integrationNameMap} from '../../utils';
import {CompanyConnections} from './company-connections';
import {CompanyCrmDataView} from './company-crm-data-view';
import {CompanyData} from './company-data';

export const CompanyProfilePage = () => {
  const goBack = useConditionallyGoBack({defaultPath: '/companies'});
  const {id: companyId = ''} = useParams();
  const hasCrmPermission = useCheckPermission(Permission.CRM);

  const {data, isLoading, refetch} = useQueryBffCompany(companyId);
  const {data: companyCrmData = []} = useQueryCompanyCrmData(companyId, {
    enabled: hasCrmPermission && Boolean(data),
  });

  const [activeTabIdx, setActiveTabIdx] = useState(0);

  const tablePagination = useTablePagination();
  const {
    currentPage,
    paginationState,
    totalCount,
    visibleItemsString,
    isFirstPage,
    isLastPage,
    nextPage,
    previousPage,
    goToPage,
  } = tablePagination;

  if (isLoading)
    return (
      <AppLayout header={<PageHeader hasBottomSeparator></PageHeader>}>
        <LoadingSpinner size="small" color="black" centered />
      </AppLayout>
    );

  if (!data) {
    return (
      <NoResults
        heading="Company not found."
        subheading="Provided link might be broken or Company Profile is not accessible."
        page="error"
      />
    );
  }

  const {name, tags} = data;
  const crms = data.crms?.map(integration => integration.crm);

  const paginationFullInfo = `${visibleItemsString} connections`;

  const connectionsView = <CompanyConnections companyData={data} tablePagination={tablePagination} />;

  const tabsConfig = [
    {
      title: 'Connections',
      content: connectionsView,
    },
    ...companyCrmData
      .filter(integration => Boolean(integration.rawData))
      .map((integration, index) => ({
        title: `${integrationNameMap[integration.crm]} Data`,
        content: <CompanyCrmDataView companyCrmData={integration} />,
        key: integration.crm + index,
      })),
  ];

  const shouldShowTabs = hasCrmPermission && companyCrmData.length > 0;
  const shouldShowPagination = activeTabIdx === 0 && totalCount > 0;

  return (
    <>
      <Helmet>
        <title>{`${name} - The Swarm`}</title>
      </Helmet>
      <AppLayout
        header={
          <PageHeader
            hasBottomSeparator
            rightSlot={
              <>
                <PremiumFeatureClickWrapper permission={Permission.CRMExport}>
                  <CrmExportMenu targetIds={[companyId]} targetType="company" onSuccess={() => refetch()} />
                </PremiumFeatureClickWrapper>
                <ReportDotsMenu target="company" />
              </>
            }
          >
            <Button onClick={goBack} icon={<BackIcon />} variant="tertiary" iconOnly>
              Go back
            </Button>
          </PageHeader>
        }
        footer={
          shouldShowPagination && (
            <PaginationButtons
              visibleItemsString={paginationFullInfo}
              pageIndex={currentPage}
              isFirstPage={isFirstPage}
              isLastPage={isLastPage}
              navigate={{
                next: nextPage,
                previous: previousPage,
                goToPage: goToPage,
              }}
              totalCount={totalCount}
              pageSize={paginationState.pageSize}
            />
          )
        }
      >
        <>
          <CompanyData
            company={data}
            tags={tags}
            crms={crms}
            onAssignTagSuccess={() => refetch()}
            onUnassignTagSuccess={() => refetch()}
          />
          {shouldShowTabs ? (
            <Tabs
              tabs={tabsConfig}
              defaultTab="Connections"
              inPage
              trackEvents
              onTabChange={setActiveTabIdx}
            />
          ) : (
            connectionsView
          )}
        </>
      </AppLayout>
    </>
  );
};

import {ApiKey} from './ApiKey';
import {ApiSupport} from './ApiSupport';
import {TeamID} from './TeamID';

export const Api = () => (
  <>
    <ApiKey />
    <TeamID />
    <ApiSupport />
  </>
);

import cs from 'classnames';
import {ReactNode} from 'react';
import {PaymentPeriod, PlanType} from '../../types';
import Styles from './PlanDetails.module.scss';

type Props = {
  name: PlanType.Premium | PlanType.Base | PlanType.Free;
  billingPeriod?: PaymentPeriod;
  price: number;
  isCurrentPlan?: boolean;
  isTrial?: boolean;
  button?: ReactNode;
  featuresTitle: string;
  horizontal?: boolean;
  className?: string;
};

const details = {
  [PlanType.Free]: {
    description: 'Invite members and start building your own Swarm.',
    features: [
      '<a href="https://www.theswarm.com/product/chrome-extension" target="_blank" class="underline">Chrome extension</a> for Linkedin',
      'Work and education overlaps',
      'Email & calendar contacts',
      'Enriched profiles',
      'Intro requests',
    ],
  },
  [PlanType.Base]: {
    description: 'Manage your network and run powerful searches.',
    features: [
      'Connection strength',
      'Company-level filters',
      'Saved lists & exports',
      'Company & profile tags',
      'Up to 100 members',
    ],
  },
  [PlanType.Premium]: {
    description: 'Unlock The Swarm for your entire team or or company.',
    features: [
      '<a href="https://www.theswarm.com/product/data-api" target="_blank" class="underline">Swarm API</a><br/>(10k monthly credits)',
      'BYO Key on Clay (Q1 2025)',
      'CRM integrations (Q1 2025)',
      'AI Network Mapper',
      'Up to 500 members',
    ],
  },
};

export const PlanDetails = ({
  name,
  billingPeriod,
  price,
  isCurrentPlan,
  isTrial,
  button,
  featuresTitle,
  horizontal,
  className,
}: Props) => (
  <div className={cs(Styles.planDetails, {[Styles.planDetailsHorizontal]: horizontal}, className)}>
    <div
      className={cs(
        Styles.planHeader,
        {[Styles.planHeaderPremium]: name === PlanType.Premium},
        {[Styles.planHeaderHorizontal]: horizontal}
      )}
    >
      <h3 className={Styles.planName} data-intercom-target="pricing plan: premium">
        {name}
      </h3>
      <p className={Styles.description}>{details[name].description}</p>
      {isTrial && isCurrentPlan ? (
        <p className={Styles.specialLabel}>Current Trial</p>
      ) : isCurrentPlan ? (
        <p className={Styles.specialLabel}>Current plan</p>
      ) : null}
    </div>
    <div className={Styles.detailsContent}>
      <div className={Styles.pricing}>
        <p className={Styles.planPrice}>${price}</p>
        <p className={Styles.paymentPeriod}>
          {!billingPeriod
            ? '/month or $2,990 /year'
            : name === PlanType.Free
              ? 'Free forever.'
              : billingPeriod === PaymentPeriod.Monthly
                ? '/team /month'
                : '/team /month, billed yearly'}
        </p>
      </div>
      <div className={Styles.features}>
        <h5 className={Styles.featuresTitle}>{featuresTitle}</h5>
        {details[name].features.map((feature, index) => (
          <p key={index}>
            ✓ <span dangerouslySetInnerHTML={{__html: feature}} />
          </p>
        ))}
      </div>
      {button}
    </div>
  </div>
);

import {ProfilesBffColumns} from '../../defs';

export const MY_CONNECTIONS_COLUMNS_PREFERENCES_KEY = 'my_connections_columns_6'; // postfix in the name to abandon old, not-compatible settings before columns naming changes
export const availableColumns = [
  ProfilesBffColumns.FullName,
  ProfilesBffColumns.ConnectionsStrength,
  ProfilesBffColumns.JobTitle,
  ProfilesBffColumns.JobCompanyName,
  ProfilesBffColumns.JobCompanyWebsite,
  ProfilesBffColumns.Emails,
  ProfilesBffColumns.LocationName,
  ProfilesBffColumns.Tags,
];

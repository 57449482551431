import cs from 'classnames';
import {ComponentType, SVGProps} from 'react';
import {PiCopy as CopyIcon} from 'react-icons/pi';
import {segmentTrack} from '../../utils';
import {copyTextToClipboard} from '../../utils/clipboard';
import {Input} from '../form';
import {notify} from '../notifications';

type CopyFieldProps = {
  value?: string;
  placeholder?: string;
  hidden?: boolean;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  iconClassName?: string;
  tracking?: {
    label: string;
    location?: string;
  };
};

export const CopyField = ({value, placeholder, hidden, icon, iconClassName, tracking}: CopyFieldProps) => {
  const copyToClipboard = (text?: string) => {
    if (tracking) {
      const {label, location} = tracking;
      segmentTrack('Button Clicked', {
        label,
        ...(location ? {location} : {}),
      });
    }

    text && copyTextToClipboard(text).then(() => notify('Copied to the clipboard'));
  };

  const isCopiable = value && !hidden;

  return (
    <div className="relative">
      <Input
        value={value || ''}
        placeholder={placeholder}
        disabled
        type={hidden ? 'password' : 'text'}
        icon={icon}
        iconClassName={iconClassName}
      />
      {isCopiable ? <CopyIcon className="absolute right-3 top-3" /> : null}
      <div
        className={cs('absolute inset-0', {['cursor-pointer']: isCopiable})}
        onClick={() => copyToClipboard(value)}
      />
    </div>
  );
};

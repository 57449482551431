import {FiltersConfig} from '../../components/filters';
import {getProfilesBffFilters, ProfilesBffFilter, profilesBffFiltersCategories} from '../../defs';

export const filtersConfig: FiltersConfig = {
  filtersName: 'memberConnectionsFilters',
  categories: profilesBffFiltersCategories,
  ...getProfilesBffFilters([
    ProfilesBffFilter.currentJobTitle,
    ProfilesBffFilter.allTimeJobTitle,
    ProfilesBffFilter.location,
    ProfilesBffFilter.linkedinUrls,
    ProfilesBffFilter.connectionStrength,
    ProfilesBffFilter.currentJobCompanyName,
    ProfilesBffFilter.currentJobCompanyNameList,
    ProfilesBffFilter.currentJobCompanyWebsite,
    ProfilesBffFilter.currentJobCompanyIndustry,
    ProfilesBffFilter.currentJobCompanySize,
    ProfilesBffFilter.currentJobCompanyLocation,
    ProfilesBffFilter.connectionSource,
  ]),
};

import axios from 'axios';
import {getJWT} from '../providers';
import {getConfig} from '../utils';

const {apiUrl = '', revision} = getConfig();

let teamId = '';
export const teamIdSetter = (newTeamId: string) => {
  teamId = newTeamId;
};
export const apiClient = axios.create({
  baseURL: apiUrl,
});

apiClient.interceptors.request.use(
  async request => {
    const token = await getJWT();
    request.headers.set('Authorization', `Bearer ${token}`);
    request.headers.set('X-Team-id', teamId);
    request.headers.set('X-Web-revision', revision);
    request.headers.set('X-App-Origin', 'WebApp');

    return request;
  },
  error => {
    return Promise.reject(error);
  }
);
